import { TweetLink } from './tweet-link';

export const FechaSemanaSanta = () => {

    const getAnioFromUrl = (): number => {
      const anio = (window.location.href.split('/')[3]
                      ? window.location.href.split('/')[3]
                      : ( (isFutureDate(juevesSantoSegunAnio(new Date().getFullYear())))
                        ? new Date().getFullYear()
                        : new Date().getFullYear() + 1)).toString();
      return parseInt(anio)
    }
    
    
    const juevesSantoSegunAnio = (year: number): Date => {
      const anio = year || new Date().getFullYear(); // Si no se proporciona el año, se utiliza el año actual
      const a = anio % 19;
      const b = Math.floor(anio / 100);
      const c = anio % 100;
      const d = Math.floor(b / 4);
      const e = b % 4;
      const f = Math.floor((b + 8) / 25);
      const g = Math.floor((b - f + 1) / 3);
      const h = (19 * a + b - d - g + 15) % 30;
      const i = Math.floor(c / 4);
      const k = c % 4;
      const l = (32 + 2 * e + 2 * i - h - k) % 7;
      const m = Math.floor((a + 11 * h + 22 * l) / 451);
      const mes = Math.floor((h + l - 7 * m + 114) / 31);
      const dia = ((h + l - 7 * m + 114) % 31) + 1;
      const fechaJuevesSanto = new Date(anio, mes - 1, dia - 3);
      return (fechaJuevesSanto);
    }
    
    const formatDate = (date: Date) => {
      return date.toLocaleDateString('es-ES', { day: 'numeric', month: 'long' })
    }
    
    const isFutureDate = (date: Date): boolean => {
      return date > new Date()
    }
    
    /* Method to add N days to a date */
    const addDays = (date: Date, days: number): Date => {
      const result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    }
  
    return (
      <>
        <div className='main-text'>
          <p>
            En el año {getAnioFromUrl()} el Jueves Santo {isFutureDate(juevesSantoSegunAnio(getAnioFromUrl())) ? 'caerá' : 'cayó'} en el día:
          </p>
          <p>
            <strong>
              {formatDate(juevesSantoSegunAnio(getAnioFromUrl()))}
            </strong>
          </p>
        </div>
        <div className='addenda'>
          <p>
            Esto significa que la Semana Santa se extiende desde el domingo de ramos {formatDate(addDays(juevesSantoSegunAnio(getAnioFromUrl()), -4))}
            <br />hasta el domingo de resurrección {formatDate(addDays(juevesSantoSegunAnio(getAnioFromUrl()), 3))}.
          </p>
        </div>
        <TweetLink url={window.location.href} text={`¿Cuándo cae la Semana Santa el año ${getAnioFromUrl()}? El Jueves Santo cae el día ${formatDate(juevesSantoSegunAnio(getAnioFromUrl()))}`} />
      </>
    )
  }