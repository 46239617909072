import './App.css';
import { useState } from 'react';
import { FechaSemanaSanta } from './components';


  function App() {
    const [anio, setAnio] = useState();

    return (
      <div className="App">
        <header className="App-header">
          <h1>
            ¿Cuándo cae la Semana Santa el año que viene?
          </h1>
        </header>
        <section>
          <FechaSemanaSanta />
        </section>
        <footer>
          <p>
            Made by <a href="https://versvs.net">Jose Alcántara</a>.
          </p>
        </footer>
      </div>
    );
  }

  export default App;
